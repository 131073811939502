import * as React from "react";
import fetch from "isomorphic-fetch";
import Client from "shopify-buy";

const client = Client.buildClient(
	{
		domain: "year001-ecco2k.myshopify.com",
		storefrontAccessToken: "42cf24478781a987eda22ccedd477c8f",
	},
	fetch
);

const defaultValues = {
	cart: [],
	isOpen: false,
	loading: false,
	onOpen: () => {},
	onClose: () => {},
	addVariantToCart: () => {},
	removeLineItem: () => {},
	updateLineItem: () => {},
	client,
	checkout: {
		lineItems: [],
	},
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
	const [checkout, setCheckout] = React.useState(defaultValues.checkout);
	const [loading, setLoading] = React.useState(false);
	const [availability, setAvailability] = React.useState({});
	const [variants, setVariants] = React.useState(false);
	const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
	const [limit, setLimit] = React.useState({});

	const fetchAvailability = (handle, callback) => {
		return client.product.fetchByHandle(handle).then((product) => {
			setAvailability({
				handle: handle,
				isAvailable: product.availableForSale,
			});
			setVariants(product.variants);
			callback(product.availableForSale);
		});
	};

	const setCheckoutItem = (checkout) => {
		if (isBrowser) {
			localStorage.setItem(localStorageKey, checkout.id);
		}

		setCheckout(checkout);
	};

	React.useEffect(() => {
		const initializeCheckout = async () => {
			const existingCheckoutID = isBrowser
				? localStorage.getItem(localStorageKey)
				: null;

			if (existingCheckoutID && existingCheckoutID !== `null`) {
				try {
					const existingCheckout = await client.checkout.fetch(
						existingCheckoutID
					);
					if (!existingCheckout.completedAt) {
						setCheckoutItem(existingCheckout);
						return;
					}
				} catch (e) {
					localStorage.setItem(localStorageKey, null);
				}
			}

			const newCheckout = await client.checkout.create();
			setCheckoutItem(newCheckout);
		};

		initializeCheckout();
	}, []);

	const limiter = (title) => {
		const titleArr = checkout.lineItems.filter((item) => {
			return item.title === title;
		});
		const totalQ = titleArr.reduce((prev, cur) => {
			return prev + cur.quantity;
		}, 0);

		let limit = false;
		if (totalQ >= 2) limit = true;

		return {
			limit,
			totalQ,
		};
	};

	const addVariantToCart = (variantId, quantity, title) => {
		setLoading(true);

		const checkoutID = checkout.id;

		const lineItemsToUpdate = [
			{
				variantId,
				quantity: 1,
			},
		];

		if (!limiter(title).limit) {
			return client.checkout
				.addLineItems(checkoutID, lineItemsToUpdate)
				.then((res) => {
					setCheckout(res);
					setLoading(false);
					setDidJustAddToCart(true);
					setLimit({ ...limit, [title]: false });
					setTimeout(() => setDidJustAddToCart(false), 3000);
				});
		} else {
			setLoading(false);
			setLimit({ ...limit, [title]: true });
		}
	};

	const removeLineItem = (checkoutID, lineItemID, title) => {
		setLoading(true);

		return client.checkout
			.removeLineItems(checkoutID, [lineItemID])
			.then((res) => {
				setCheckout(res);
				setLoading(false);
				setLimit({ ...limit, [title]: false });
			});
	};

	const updateLineItem = (checkoutID, lineItemID, quantity, type, title) => {
		setLoading(true);

		const lineItemsToUpdate = [
			{ id: lineItemID, quantity: parseInt(quantity, 10) },
		];

		if (!limiter(title).limit || type === "minus") {
			return client.checkout
				.updateLineItems(checkoutID, lineItemsToUpdate)
				.then((res) => {
					setCheckout(res);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	};

	return (
		<StoreContext.Provider
			value={{
				...defaultValues,
				addVariantToCart,
				removeLineItem,
				updateLineItem,
				fetchAvailability,
				variants,
				checkout,
				loading,
				availability,
				didJustAddToCart,
				limit,
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};
